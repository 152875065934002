.error-404-section {
  text-align: center;
}

.error-404-section .error-message {
  margin-top: 70px;
  padding: 0 200px;
}

@media (max-width: 991px) {
  .error-404-section .error-message {
      margin-top: 50px;
      padding: 0 100px;
  }
}

@media (max-width: 767px) {
  .error-404-section .error-message {
      padding: 0;
  }
}

.error-404-section .error-message h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 0 0 0.8em;
}

.error-404-section .error-message p {
  margin-bottom: 1.8em;
}

.error-message .theme-btn-s4 {
  background-color: #0d8342;
  color: #fff;
}
