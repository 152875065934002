.header-style-2{
	position: absolute;
    z-index: 120;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	box-shadow: none;
}
.header-style-2 nav ul li a{
	color: #000;
}
.header-style-2 nav ul li a:hover{
	color: #178143;
}
.header-style-2 nav ul li a:before{
 display: none;
}

.header-style-2 .cart-search-contact button > i{
  color: #fff;
}


.header-style-2 nav ul li ul li a {
    color: #333;
}

.header-style-2 nav ul li ul li a:hover {
    color: #029ca5;
}

.header-topbar {
    background: #178143;
    padding: 0 100px;
}

@media (max-width: 991px) {

    .header-topbar {
        text-align: center;
        padding: 0;
    }
}

@media (max-width: 767px) {

    .header-style-1 {
        text-align: center;
    }
}

