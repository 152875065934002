/*===================================================
#4.2 service-section
=====================================================*/

.service-single-item {
    box-shadow: 0px 5px 9.8px 0.2px rgba(85, 85, 85, 0.07);
    margin-bottom: 30px;
}

.service-text {
    padding: 20px;
}

.service-text h2 {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.service-text h2 a {
    color: #0d8342;
}

.service-text h2 a:hover {
    color: #b1262a;
}

.service-single-img img {
    width: 100%;
}

.service-area-2 {
    margin-bottom: 70px;
}

.wpo-event-area-2 {
    background: #fff;
    border-bottom: 1px solid #f9f9fc;
}


.service-area-3 {
    padding-bottom: 70px;
}

.service-area-2.s2{
    padding-top: 100px;
}

@media(max-width: 767px){
    .service-text h2 {
        font-size: 20px;
    }

    .service-area-2 {
        margin-bottom: 50px;
    }
}