.wpo-event-details-area .wpo-event-img{
  margin-bottom: 30px;
}
.wpo-event-details-area .wpo-event-img {
  float: none;
  box-shadow: 0px 3px 7px 0px rgb(90 90 90 / 10%);
}
.wpo-event-details-text h2 {
  margin-bottom: 30px;
}

.wpo-event-details-wrap {
  margin-top: 40px;
}

.wpo-event-details-tab .nav {
  justify-content: space-between;
  display: flex;
}

.wpo-event-details-tab .nav-tabs>li {
  flex-basis: 33.33%;
}

.wpo-event-details-wrap {
  border: 1px solid #ebebeb;
}

.wpo-event-details-tab .nav li a {
  display: block;
  text-align: center;
  color: #2d4277;
  transition: all .5s;
  font-weight: 700;
  margin-right: 1px;
  border: none;
  border-radius: 0px;
  background: #f6f6f6;
  border-bottom: none;
  border-top: 4px solid transparent;
  line-height: 20px;
  height: 50px;
  cursor: pointer;
}

.wpo-event-details-tab .nav li:last-child a {
  margin-right: -1px;
}

.wpo-event-details-tab .nav li a.active,
.wpo-event-details-tab .nav li:hover a {
  background: #fff;
  outline: none;
  border: none;
  color: #2d4277;
  border-top: 4px solid #029ca5;
}

.wpo-event-details-content {
  padding: 30px;
}

#Schedule ul {
  list-style: none;
  margin-top: 30px;
}

#Schedule ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}

#Schedule ul li:before {
  content: "";
  background: #029ca5;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0;
  top: 6px;
}

.enent-map iframe {
  width: 100%;
  height: 500px;
}

.event-contact .wpo-donations-details {
  border: none;
  padding: 0;
}

.event-contact .wpo-donations-details .theme-btn {
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: none;
  margin-top: 20px;
}

@media(max-width: 590px) {
  .wpo-event-details-tab .nav li a {
      font-size: 12px;
  }
}

@media(max-width: 400px) {
  .wpo-event-details-tab .nav {
      flex-wrap: wrap;
  }

  .wpo-event-details-tab .nav li a {
      margin-bottom: 15px;
  }
}

.contact-map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}